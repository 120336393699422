import React from 'react'
import FeedbackQuestionnaireView from '../overallFeedback/FeedbackQuestionnaireView'
import { Box, Typography } from '@mui/material'
import AreaOfImprovementView from '../overallFeedback/AreaOfImprovementView';
import TrainingRecommendationView from '../overallFeedback/TrainingRecommendationView';
import AppraiserFurtherRecommendationView from '../overallFeedback/AppraiserFurtherRecommendationView';
import AppraiserMessageForEmployeeView from '../overallFeedback/AppraiserMessageForEmployeeView';
import PerformanceGoals from './PerformanceGoals';
import NormalizerCommentsView from '../overallFeedback/NormalizerCommentsView';
import NormalizerMeetingNotesView from '../overallFeedback/NormalizerMeetingNotesView';
import NormalizerAttachmentView from '../overallFeedback/NormalizerAttachmentView';
import NormalizerDiscussedRatingView from '../overallFeedback/NormalizerDiscussedRatingView';
import EmployeeCommentsView from '../overallFeedback/EmployeeCommentsView';
import OneToOneMeetingDateView from '../overallFeedback/OneToOneMeetingDateView';

function OverallFeedBack(props: any) {
  const { employeeData, appraiserFeedbackQuestionnaire, showAppraiserAreaOfImprovement, appraiserAreaOfImprovement,
    showAppraiserTrainingRecommendation, appraiserTrainingRecommendation, furtherRecommendation, furtherRecommendationOthers,
    showAppraiserMessageForEmployee, appraiserMessageForEmployee, performanceGoalsFieldValues, performanceGoalsFieldValuesEmployee, showPerformanceGoalsFieldValuesEmployee
    , showPerformanceGoalsFieldValues,
    employeeAreaOfImprovement,
    showEmployeeAreaOfImprovement,
    employeeTrainingRecommendation,
    showEmployeeTrainingRecommendation,
    normalizerComments,
    showNormalizerComments,
    normalizerMeetingNotes,
    shownormalizermeetingnotes,
    showNormalizerMeetingAttachment,
    normalizerMeetingAttachment,
    shownormalizerdiscussedrating,
    appraiserChecked,
    reviewerChecked,
    employeeChecked,
    showEmployeeComments,
    employeeComments,
    oneToOneMeetingDate,
    showoneToOneMeetingDate,
    className
  } = props;
  let showIfAppraiser = true
  let showIfEmployee = true
  let showIfpreviousappraisal = true

  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "20px",
          color: "#3E8CB5",
          fontFamily: "Arial",
          marginBottom: "20px",
        }}
      >
        Performance Appraisal Summary
      </Typography>
      <div style={{ marginRight: "33px", }}>
        <FeedbackQuestionnaireView
          showIfpreviousappraisal={showIfpreviousappraisal}
          employeeData={employeeData}
          appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire} />
      </div>
      {(showAppraiserAreaOfImprovement &&
        <AreaOfImprovementView
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfAppraiser={showIfAppraiser}
          showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
          areaOfImprovement={appraiserAreaOfImprovement} />
      )}
      {(showEmployeeAreaOfImprovement &&
        <AreaOfImprovementView
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfEmployee={showIfEmployee}
          showAppraiserAreaOfImprovement={showEmployeeAreaOfImprovement}
          areaOfImprovement={employeeAreaOfImprovement} />
      )}
      {showAppraiserTrainingRecommendation && (
        <TrainingRecommendationView
          showIfpreviousappraisal={showIfpreviousappraisal}
          showIfAppraiser={showIfAppraiser}
          showTrainingRecommendation={showAppraiserTrainingRecommendation}
          trainingRecommendation={appraiserTrainingRecommendation} />
      )}
      {showEmployeeTrainingRecommendation && (
        <TrainingRecommendationView
          showIfEmployee={showIfEmployee}
          showIfpreviousappraisal={showIfpreviousappraisal}
          showTrainingRecommendation={showEmployeeTrainingRecommendation}
          trainingRecommendation={employeeTrainingRecommendation} />
      )}
      <AppraiserFurtherRecommendationView
        showIfpreviousappraisal={showIfpreviousappraisal}
        furtherRecommendation={furtherRecommendation}
        furtherRecommendationOthers={furtherRecommendationOthers} />
      {showAppraiserMessageForEmployee && (
        <div>
          <AppraiserMessageForEmployeeView
            appraiserMessageForEmployee={appraiserMessageForEmployee}
            employeeData={employeeData} />
        </div>
      )}

      {showEmployeeComments &&
        <EmployeeCommentsView
          employeeComments={employeeComments} />
      }

      {showoneToOneMeetingDate &&
        <OneToOneMeetingDateView
          oneToOneMeetingDate={oneToOneMeetingDate} />
      }

      {(showIfpreviousappraisal && showNormalizerComments) && (
        <NormalizerCommentsView
          showNormalizerComments={showNormalizerComments}
          normalizerComments={normalizerComments} />
      )}

      {shownormalizerdiscussedrating &&
        <NormalizerDiscussedRatingView
          appraiserChecked={appraiserChecked}
          reviewerChecked={reviewerChecked}
          employeeChecked={employeeChecked}
          shownormalizerdiscussedrating={shownormalizerdiscussedrating} />
      }

      {shownormalizermeetingnotes &&
        <NormalizerMeetingNotesView
          shownormalizermeetingnotes={shownormalizermeetingnotes}
          normalizerMeetingNotes={normalizerMeetingNotes} />
      }

      {showNormalizerMeetingAttachment &&
        <NormalizerAttachmentView
          normalizerMeetingAttachment={normalizerMeetingAttachment}
        />
      }

      {/* <div className="page-break">
        <PerformanceGoals
          performanceGoalsFieldValues={performanceGoalsFieldValues}
          performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
          showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
          showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
          showIfAppraiser={showIfAppraiser}
          showIfEmployee={showIfEmployee} />
      </div> */}



    </div >
  )
}

export default OverallFeedBack