import React from 'react'
import Header from '../header/Header'
import {
  Box, Stack, Typography, Popover, TableContainer, Table,
  TableHead, TableCell, TableRow,
  TableBody,
  IconButton
} from '@mui/material'
import PotentialView from '../potential/potentialView'
import PerformanceAppraisalPeriod from '../performanceAppraisalPeriod/PerformanceAppraisalPeriod'
import OverallFeedbackTab from '../appraiser/OverallFeedbackTab'
import OverallFeedBack from './OverallFeedBack'
import RatingsTab from '../ratings/RatingsTab'
import { styled } from "@mui/material/styles";
import { Scrollbar } from "react-scrollbars-custom";
import Infoicon from "../../../assets/Images/Infoicon.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import PerformanceGoals from './PerformanceGoals'

const Scroll = styled("div")({
  "& .ScrollbarsCustom-Thumb": {
    background: "#C2C1C1 !important",
  },
});
function PreviousAppraisalViewPa(props: any) {
  const { showPerformanceGoalsFieldValuesEmployee,
    showIfViewPa, positionHide, employeeData, employeecode,
    pdfExportComponent, handleHide, ratingDefinition,
    ratingData, overallRating, PAStatus, ratingScaleDefinition,
    employeeDetails, showPotential, potential,
    furtherRecommendationOthers, showPerformanceGoalsFieldValues,
    performanceGoalsFieldValues, performanceGoalsFieldValuesEmployee,
    appraiserFeedbackQuestionnaire, showAppraiserAreaOfImprovement,
    appraiserAreaOfImprovement, showEmployeeAreaOfImprovement, showAppraiserTrainingRecommendation, employeeAreaOfImprovement, employeeTrainingRecommendation, showEmployeeTrainingRecommendation, objectiveDescription,
    appraiserTrainingRecommendation, showAppraiserMessageForEmployee, employee_id,
    appraiserMessageForEmployee, objectiveTitleData, Colors, colorarray, visiblityColumnData,
    appraiserChecked, reviewerChecked, employeeChecked, shownormalizerdiscussedrating,
    furtherRecommendation,
    normalizerComments,
    showNormalizerComments,
    normalizerMeetingNotes,
    shownormalizermeetingnotes,
    normalizerMeetingAttachment,
    showNormalizerMeetingAttachment,
    employeeComments,
    showEmployeeComments,
    showoneToOneMeetingDate,
    oneToOneMeetingDate

  } = props
  let showIfpreviousappraisal = true
  let showIfAppraiser = true
  let showIfEmployee = true
  const CustomScrollbar = Scrollbar as any;
  let showDownload = true
  // Popovers
  const [anchorRatingInfo, setAnchorRatingInfo] = React.useState<HTMLButtonElement | null>(null);
  const openRatingInfo = Boolean(anchorRatingInfo);
  const idRatingInfo = openRatingInfo ? "simple-popover" : undefined;

  const handleClickOpenRatingInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRatingInfo(event.currentTarget);
  };

  const handleCloseRatingInfo = () => {
    setAnchorRatingInfo(null);
  };
  return (
    <>
      <PDFExport
        paperSize="A4"
        scale={0.40}
        keepTogether="p"
        forcePageBreak=".page-break" ref={pdfExportComponent}
        fileName={`PA_${employeecode}.pdf`}>
        <div
          // id="pdf"
          style={{
            backgroundColor: "#F1F1F1",
            // height: "1500px",
            minHeight: "100px",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              marginRight: "25px",
              marginLeft: "25px",
              background: "#fff",
              marginBottom: "25px"
            }}
          >
            <Box
              style={{
                padding: "35px",
                paddingBottom: "35px"
              }}
            >
              <Header
                showDownload={showDownload}
                showIfpreviousappraisal={showIfpreviousappraisal}
                positionHide={positionHide}
                pdfExportComponent={pdfExportComponent}
                handleHide={handleHide}
                ratingData={ratingData}
                employeeDetails={employeeDetails}
                overallRating={overallRating}
                PAStatus={PAStatus}
                employeeData={employeeData}
                ratingDefinition={ratingDefinition}
                ratingScaleDefinition={ratingScaleDefinition}
                employee_id={employee_id}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
              >
                <div style={{
                  marginTop: "10px",
                  marginBottom: "10px"
                }}>
                  <PerformanceAppraisalPeriod
                    employeeDetails={employeeDetails} />
                </div>
                <div style={{
                  marginTop: "10px",
                  marginBottom: "10px"
                }}>
                  <PotentialView
                    showIfpreviousappraisal={showIfpreviousappraisal}
                    employeeData={employeeData}
                    potential={potential}
                    // nineBoxData={nineBoxData}
                    showPotentialView={showPotential}
                  />
                </div>
              </Stack>
              <Stack direction="row" alignItems="center" >
                <Typography style={{ color: "#3e8cb5", fontSize: "16px", fontFamily: "Arial", }}>
                  <IconButton
                    sx={{ "&.MuiTab-iconWrapper": { marginLeft: "0px" } }}
                    onClick={handleClickOpenRatingInfo}
                  >
                    <img
                      style={{ width: "12px" }}
                      src={Infoicon}
                      alt="icon"
                    />
                  </IconButton> <b>Ratings</b>
                </Typography>
              </Stack>

              <RatingsTab
                showIfpreviousappraisal={showIfpreviousappraisal}
                employee_id={employee_id}
                employeeData={employeeData}
                objectiveDescription={objectiveDescription}
                objectiveTitleData={objectiveTitleData}
                Colors={Colors}
                colorarray={colorarray}
                visiblityColumnData={visiblityColumnData}
              />
              <div className="page-break">
                <Typography
                  style={{ color: "#3e8cb5", fontSize: "16px", fontFamily: "Arial", paddingTop: "20px", marginBottom: "20px" }}
                >
                  <b>Overall Feedback</b>
                </Typography>
                <OverallFeedBack
                  showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
                  showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
                  performanceGoalsFieldValues={performanceGoalsFieldValues}
                  performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
                  employeeData={employeeData}
                  appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
                  showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
                  appraiserAreaOfImprovement={appraiserAreaOfImprovement}
                  showAppraiserTrainingRecommendation={showAppraiserTrainingRecommendation}
                  appraiserTrainingRecommendation={appraiserTrainingRecommendation}
                  showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
                  appraiserMessageForEmployee={appraiserMessageForEmployee}
                  appraiserChecked={appraiserChecked}
                  reviewerChecked={reviewerChecked}
                  employeeChecked={employeeChecked}
                  shownormalizerdiscussedrating={shownormalizerdiscussedrating}
                  furtherRecommendation={furtherRecommendation}
                  furtherRecommendationOthers={furtherRecommendationOthers}
                  employeeAreaOfImprovement={employeeAreaOfImprovement}
                  showEmployeeAreaOfImprovement={showEmployeeAreaOfImprovement}
                  employeeTrainingRecommendation={employeeTrainingRecommendation}
                  showEmployeeTrainingRecommendation={showEmployeeTrainingRecommendation}
                  showNormalizerComments={showNormalizerComments}
                  normalizerComments={normalizerComments}
                  shownormalizermeetingnotes={shownormalizermeetingnotes}
                  normalizerMeetingNotes={normalizerMeetingNotes}
                  normalizerMeetingAttachment={normalizerMeetingAttachment}
                  showNormalizerMeetingAttachment={showNormalizerMeetingAttachment}
                  employeeComments={employeeComments}
                  showEmployeeComments={showEmployeeComments}
                  oneToOneMeetingDate={oneToOneMeetingDate}
                  showoneToOneMeetingDate={showoneToOneMeetingDate}
                />
              </div>
              <div className="page-break">
                <PerformanceGoals
                  performanceGoalsFieldValues={performanceGoalsFieldValues}
                  performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
                  showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
                  showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
                  showIfAppraiser={showIfAppraiser}
                  showIfEmployee={showIfEmployee} />
              </div>
              <Popover
                id={idRatingInfo}
                open={openRatingInfo}
                anchorEl={anchorRatingInfo}
                onClose={handleCloseRatingInfo}
                PaperProps={{
                  style: { width: "260px", marginTop: "55px" },
                }}
              >
                <TableContainer sx={{ overflowX: "hidden" }}>
                  <Scroll>
                    <CustomScrollbar style={{ width: "100%", height: "225px" }}>
                      <Table
                        sx={{
                          minWidth: 200,
                          '& th, & td': {
                            borderBottom: 'none', // Remove the bottom border for th and td
                          },
                        }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow sx={{ bgcolor: "#eaeced" }}>
                            <TableCell
                              align="center"
                              sx={{
                                maxWidth: "30%",
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600", // Adjust the maxWidth as needed
                              }}
                            >
                              Ratings
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                maxWidth: "70%",
                                fontFamily: "Arial",
                                color: "#3E8CB5",
                                fontSize: "14px",
                                fontWeight: "600",  // Adjust the maxWidth as needed
                              }}
                            >
                              Rating Title
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {employeeData &&
                            employeeData?.employee?.rating_scale
                              ?.slice()
                              ?.sort(function (a: any, b: any) {
                                return a.rating - b.rating;
                              })
                              ?.map((row: any, index: any) => {
                                return (
                                  <TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        borderColor: "lightgrey",
                                      },
                                    }}
                                  >

                                    <TableCell
                                      align="center"
                                      sx={{
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                      }}
                                    >
                                      <div
                                        style={{
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {row.rating}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        fontSize: "14px",
                                        color: "#333333",
                                        fontFamily: "Arial",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "147px",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {row.rating_scale}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </CustomScrollbar>
                  </Scroll>
                </TableContainer>
              </Popover>
            </Box>
          </Box>
        </div>
      </PDFExport>
    </>
  )
}

export default PreviousAppraisalViewPa