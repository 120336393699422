import React, { useState, useEffect } from "react";
import {
  useGetObjectiveDescriptionQuery,
  useGetObjectiveTitleQuery,
  useGetObjectiveTypeQuery,
  useGetRatingScaleQuery
} from "../../../../../service";
import { useParams } from "react-router-dom";
import { useGetpastAppraisalDetailsofEmployeeQuery } from "../../../../../service/employee/previousAppraisal";
import { PDFExport } from "@progress/kendo-react-pdf";
import _ from "lodash";
import PreviousAppraisalViewPa from "../../../../../components/appraisalNew/previousAppraisalViewPa/Previous_Dashboard_Appraisal_ViewPa";

const Previous_Appraisal_ViewPa = () => {
  const { data: ratingScaleData } = useGetRatingScaleQuery("");
  const { employee_id } = useParams();
  const { data } = useGetObjectiveTypeQuery("");
  const { data: objectiveTitleData } = useGetObjectiveTitleQuery("");
  const { data: objectiveDescData, isLoading: isLoadingDescription } = useGetObjectiveDescriptionQuery("");
  const { data: employeeData, isLoading } = useGetpastAppraisalDetailsofEmployeeQuery(employee_id);
  console.log(employeeData, "employeeData");
  const { data: ratingData } = useGetRatingScaleQuery("");
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [overallRating, setOverallRating] = useState(0)
  const [ratingDefinition, setRatingDefinition] = useState<any>();
  const [ratingScaleDefinition, setRatingScaleDefinition] = useState<any>();
  const [PAStatus, setPAStatus] = useState("Completed")
  const [appraiserFeedbackQuestionnaire, setAppraiserFeedbackQuestionnaire] = useState<any>([]);
  const [appraiserAreaOfImprovement, setAppraiserAreaOfImprovement] = useState([]);
  const [feedbackQuestionnaire, setFeedbackQuestionnaire] = useState<any>([]);
  const [appraiserTrainingRecommendation, setAppraiserTrainingRecommendation] = useState([])
  const [appraisalTemplateTrainingRecommendation, setAppraisalTemplateTrainingRecommendation] = useState([]);
  const [appraiserFurtherRecommendation, setAppraiserFurtherRecommendation] = useState<any>([]);
  const [appraiserFurtherRecommendationOthers, setAppraiserFurtherRecommendationOthers] = useState<any>("")
  const [appraiserFurtherRecommendationOthersCheckbox, setAppraiserFurtherRecommendationOthersCheckbox] = useState<any>(false)
  const [appraiserMessageForEmployee, setAppraiserMessageForEmployee] = useState("")
  const [showAppraiserMessageForEmployee, setShowAppraiserMessageForEmployee] = useState(false)
  const [oneToOneMeetingDate, setOneToOneMeetingDate] = useState("");
  const [showoneToOneMeetingDate, setShowoneToOneMeetingDate] = useState(false);
  const [employeeComments, setEmployeeComments] = useState("");
  const [showEmployeeComments, setShowEmployeeComments] = useState(false);
  const [employeeTrainingRecommendation, setEmployeeTrainingRecommendation] = useState([])
  const [showAppraiserTrainingRecommendation, setShowAppraiserTrainingRecommendation] = useState(false);
  const [showEmployeeTrainingRecommendation, setShowEmployeeTrainingRecommendation] = useState(false);
  const [employeeAreaOfImprovement, setEmployeeAreaOfImprovement] = useState<any>([]);
  const [showAppraiserAreaOfImprovement, setShowAppraiserAreaOfImprovement] = useState(false);
  const [showEmployeeAreaOfImprovement, setShowEmployeeAreaOfImprovement] = useState(false);
  const [normalizerComments, setNormalizerComments] = useState<any>("");
  const [showNormalizerComments, setShowNormalizerComments] = useState(false);
  const [showReviewerRejectionReason, setShowReviewerRejectionReason] = useState(false);
  const [reviewerRejectionReason, setReviewerRejectionReason] = useState<any>("");
  const [shownormalizerRejectionReason, setShowNormalizerRejectionReason] = useState(false);
  const [normalizerRejectionReason, setNormalizerRejectionReason] = useState<any>("");
  const [shownormalizerdiscussedrating, setShownormalizerdiscussedrating] = useState<any>("");
  const [shownormalizermeetingnotes, setShownormalizermeetingnotes] = useState<any>("");
  const [normalizerMeetingNotes, setNormalizerMeetingNotes] = useState<any>("");
  const [showreviewerComments, setShowReviewerComments] = useState(false);
  const [reviewerComments, setReviewerComments] = useState<any>("");
  const [performanceGoalsFieldValues, setPerformanceGoalsFieldValues] = useState<any>("");
  const [performanceGoalsFieldValuesEmployee, setPerformanceGoalsFieldValuesEmployee] = useState<any>("");
  const [showPerformanceGoalsFieldValues, setShowPerformanceGoalsFieldValues] = useState<any>("");
  const [showPerformanceGoalsFieldValuesEmployee, setShowPerformanceGoalsFieldValuesEmployee] = useState<any>("");
  const [showPotential, setShowPotential] = useState(false);
  const [potential, setPotential] = useState("")
  const [appraiserChecked, setAppraiserChecked] = useState(false);
  const [reviewerChecked, setReviewerChecked] = useState(false);
  const [employeeChecked, setEmployeeChecked] = useState(false);
  const [normalizerMeetingAttachment, setNormalizerMeetingAttachment] = useState<any>("");
  const [showNormalizerMeetingAttachment, setShowNormalizerMeetingAttachment] = useState<any>("");
  const [showNormalizerFooterButton, setShowNormalizerFooterButton] = useState(false);
  const [showReviewerFooterButton, setShowReviewerFooterButton] = useState(false);
  const [showAppraiserFooterButton, setShowAppraiserFooterButton] = useState(false);
  const [objectiveDescription, setObjectiveDescription] = useState<any>([]);
  const [colorarray, setColorarray] = useState<any>("");
  const [positionHide, setpositionHide] = useState<any>(true);
  const [employeecode, setemployeecode] = useState<any>([])
  const [furtherRecommendationOthers, setFurtherRecommendationOthers] = useState<any>("");
  const [furtherRecommendation, setFurtherRecommendation] = useState<any>("");

  const [visiblityColumnData, setVisiblityColumnData] = useState({
    showPreviousRating: false,
    showAppraiserComments: false,
    showAppraiserRejectionReason: false,
    showReviewerRating: false,
    showReviewerRejectionReason: false,
    showEmployeeRating: false,
    showEmployeeRejectionReason: false,
    showRenormalizedRating: false,
    showHRNormalizerComments: false
  });
  const Colors = [
    "#B6E9EE",
    "#C9FBEA",
    "#B1EDEE",
    "#B9E9D0",
    "#BDE3E2",
    "#B1F3F2",
    "#B7E6F7",
    "#B8EFEF",
    "#BFFBE7",
    "#B7E6F7",
    "#B1F1F0",
    "#BEECF5",
  ]

  const hideAlertHandler = () => {
    setTimeout(() => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    }, 1000);
    setTimeout(() => {
      setpositionHide(true)
    }, 3000);
  };
  const handleHide = () => {
    setpositionHide(false)
    hideAlertHandler()
  }
  // to find objective title by Id
  const findObjectiveTitleById = (id: any) => {
    if (objectiveTitleData) {
      return objectiveTitleData.data.find((item: any) => item._id === id);
    }
  };
  // to find objective type by Id
  const findObjectiveTypeById = (id: any) => {
    if (employeeData) {
      return employeeData?.employee?.objective_type?.find(
        (item: any) => item?.name?._id === id
      );
    }
  };
  // to find Previous Rating
  const findPreviousRating = (id: any) => {
    let temp = (employeeData?.employee?.objective_description?.filter(
      (i: any) =>
        i?.name === id
    ).map((k: any) => {
      if (ratingData) {
        let temp = ratingData?.data?.find((item: any) => k.ratings == item._id)
        return temp?.rating
      }
    })[0]);
    return temp
  }
  //  to find employee rating
  const findEmployeeRating = (id: any) => {
    let temp = (employeeData?.employee?.objective_description
      .filter(
        (i: any) =>
          i?.name?._id === id
      )
      .map((k: any) => {
        if (k?.ratings && k?.rating_rejected == true)
          return {
            rating_rejected: k?.rating_rejected,
            ratings: k.ratings,
            rejection_reason: k?.rejection_reason
          }
        else return ""
      })[0]);
    return temp
  }
  //  to find Normalizer Rating
  const findNormalizerRating = (id: any) => {
    let temp = (employeeData?.employee?.normalizer?.objective_description
      .filter(
        (i: any) =>
          i?.name?._id === id
      )
      .map((k: any) => {
        return {
          rating_resubmitted: k?.rating_resubmitted,
          ratings: k?.ratings,
          rejection_reason: k?.rejection_reason
        }
      })[0]);
    return temp
  }
  // group Area of Improvement by specific Area
  const groupSpecificAreaHandler = (area: any) => {
    if (area) {
      let tempArea = area?.filter((area: any) => {
        return area[0] !== "" && area[0] !== undefined
      })
      if (tempArea && tempArea?.length > 0) {
        return {
          showArea: true,
          area: area,
        }
      } else {
        return {
          showArea: false
        }
      }
    }
  };
  const groupNAmeHandler = (name: any) => {
    if (name) {
      let tempArea = name.filter((area: any) => {
        return area[0] !== "" && area[0] !== undefined
      })
      if (tempArea && tempArea?.length > 0) {
        setShowAppraiserAreaOfImprovement(true);
        // setFilterData1(name);
      } else {
        setShowAppraiserAreaOfImprovement(false);
      }

    }
  };

  const groupNAmeHandler1 = (name: any) => {
    if (name) {
      let tempArea = name.filter((area: any) => {
        return area[0] !== "" && area[0] !== undefined
      })
      if (tempArea && tempArea?.length > 0) {
        setShowEmployeeAreaOfImprovement(true);
        // setFilterData1(name);
      } else {
        setShowEmployeeAreaOfImprovement(false);
      }

    }
  };

  useEffect(() => {
    setPotential(employeeData?.employee?.appraisal?.potential);
    setEmployeeDetails(() => {
      return {
        employee_code: employeeData?.employee?.employee_code,
        previousRating: employeeData?.employee?.previous_rating,
        profile_image_url: employeeData?.employee?.profile_image_url,
        fullName: employeeData?.employee?.legal_full_name,
        firstName: employeeData?.employee?.first_name,
        position: employeeData?.employee?.position_long_description,
        grade: employeeData?.employee?.grade,
        status: employeeData?.employee?.appraisal?.status,
        calendarName: employeeData?.employee?.calendar?.name,
      }
    });
    setObjectiveDescription(() => {
      let findObjectiveTypeByName: any = (ObjectiveTitleName: any) => {
        return employeeData?.employee?.objective_type?.find((item: any) => item?._id === ObjectiveTitleName)?.objective_type
      }

      return employeeData?.employee?.objective_description?.map(
        (i: any, index1: any) => {
          return {
            ...i,
            objective_title: i.objectiveTitle,
            objective_type: findObjectiveTypeByName(i.objective_type),
          };
        }
      );
    });
    setVisiblityColumnData({
      ...visiblityColumnData,
      // show the previous rating popover if any previous rating data is present.
      showPreviousRating: (employeeData?.employee?.objective_description?.filter((i: any) => i?.ratings)?.length > 0) ? true : false,
      // show appraiser comments column if any of the rating has appraiser comments.
      showAppraiserComments: (employeeData?.employee?.objective_description?.filter((item: any) =>
        item?.comments !== "" && item?.comments !== undefined)?.length > 0) ? true : false,
      /**show appraiser rejection reason if appraiser has rejected any rating and added rejection reason and also if 
               if the PA is not renormalized or completed *****/
      showAppraiserRejectionReason: (employeeData?.employee?.appraisal?.status !== "completed" &&
        employeeData?.employee?.objective_description?.filter((item: any) =>
          ((item?.rating_resubmitted && employeeData?.employee?.appraisal?.status !== "rejected") || item?.rating_rejected
          ) && (item?.rejection_reason !== "" && item?.rejection_reason !== undefined))?.length > 0) ? true : false,
      showReviewerRating: (employeeData?.employee?.reviewer?.objective_description?.filter((item: any) =>
        item?.rating_rejected == true)?.length > 0) ? true : false,
      showReviewerRejectionReason: (employeeData?.employee?.reviewer?.objective_description?.filter((item: any) =>
        item?.rating_rejected == true)?.length > 0) ? true : false,
      /**show employee rating if any of the rating has been rejected by the employee */
      showEmployeeRating: ((employeeData?.employee?.normalizer?.normalizer_status !== "re-normalized") && (employeeData?.data?.employee?.objective_description?.filter((item: any) =>
        item?.rating_rejected == true)?.length > 0)) ? true : false,
      showEmployeeRejectionReason: ((employeeData?.employee?.normalizer?.normalizer_status !== "re-normalized") && (employeeData?.data?.employee_previous_submission?.objective_description?.filter((item: any) =>
        item?.rating_rejected == true)?.length > 0)) ? true : false,
      /**Show normalizer rating*/
      showRenormalizedRating: (employeeData?.employee?.normalizer?.objective_description?.filter((item: any) =>
        item?.rating_resubmitted == true)?.length > 0) ? true : false,
      /** Show Normalizer comments */
      showHRNormalizerComments: (employeeData?.employee?.normalizer?.objective_description?.filter((item: any) =>
        item?.rating_resubmitted == true).length > 0) ? true : false

    })
    let objectiveType = employeeData?.employee?.objective_type?.map((item: any, index: number) => {
      return {
        objective_type: item?.name?.name,
        color: Colors[index]
      }
    });
    setColorarray(objectiveType)
    setemployeecode(employeeData?.employee?.employee_code)
    setShowPotential(employeeData?.employee?.appraisal?.potential != "" || employeeData?.employee?.appraisal?.potential != undefined);
    setOverallRating(employeeData?.employee?.overall_rating);
    setAppraiserFeedbackQuestionnaire(employeeData?.employee?.feedback_questions)
    setAppraiserAreaOfImprovement(employeeData?.employee?.area_of_improvement);
    setEmployeeAreaOfImprovement(employeeData?.employee?.area_of_improvements_employee)
    setAppraiserTrainingRecommendation(employeeData?.employee?.training_recommendation);
    setEmployeeTrainingRecommendation(employeeData?.employee?.training_recommendations_employee)
    setPerformanceGoalsFieldValues(employeeData?.employee?.appraisal?.performance_goal)
    setPerformanceGoalsFieldValuesEmployee(employeeData?.employee?.employee?.performance_goal)
    setAppraiserChecked(
      employeeData?.employee?.normalizer?.isAppraiserChecked
    );
    setReviewerChecked(
      employeeData?.employee?.normalizer?.isReviewerChecked
    );
    setEmployeeChecked(
      employeeData?.employee?.normalizer?.isEmployeeChecked
    );
    setEmployeeComments(employeeData?.employee?.employee?.comments);
    setShowEmployeeComments(employeeData?.employee?.employee?.comments !== undefined &&
        employeeData?.employee?.employee?.comments !== "");
    let performancegoalvaluesappraiser = employeeData?.employee?.appraisal?.performance_goal
    let performancegoalvaluesemployee = employeeData?.employee?.employee?.performance_goal
    if (performancegoalvaluesappraiser?.length == 0) {
      setShowPerformanceGoalsFieldValues(false)
    } else {
      setShowPerformanceGoalsFieldValues(true)
    }
    if (performancegoalvaluesemployee?.length == 0) {
      setShowPerformanceGoalsFieldValuesEmployee(false)
    } else {
      setShowPerformanceGoalsFieldValuesEmployee(true)
    }
    let tempTraining = employeeData?.employee?.training_recommendation?.filter((item: any) => {
      return item?.name?.title !== "" || item?.name?.title !== undefined
    });

    if (tempTraining && tempTraining?.length > 0) {
      setShowAppraiserTrainingRecommendation(true);
    } else {
      setShowAppraiserTrainingRecommendation(false)
    }

    let tempTraining1 = employeeData?.employee?.training_recommendations_employee?.filter((item: any) => {
      return item?.title !== "" || item?.title !== undefined
    });

    if (tempTraining1 && tempTraining1?.length > 0) {
      setShowEmployeeTrainingRecommendation(true);
    } else {
      setShowEmployeeTrainingRecommendation(false)
    }

    setFurtherRecommendation(() => {
      return employeeData?.employee?.other_recommendation?.map(
        (i: any) => {
          return {
            ...i,
            name: i?.name,
          };
        }
      );
    });
    if (employeeData && employeeData?.employee) {
      const employeeAreaofImprovement =
        employeeData?.employee?.area_of_improvement;
      const group = _.groupBy(employeeAreaofImprovement, "value");
      const groupName = groupNAmeHandler(Object.entries(group));
    }
    if (employeeData && employeeData?.employee) {
      const employeeAreaofImprovement =
        employeeData?.employee?.area_of_improvements_employee;
      const group = _.groupBy(employeeAreaofImprovement, "value");
      const groupName = groupNAmeHandler1(Object.entries(group));
    }
    // to get Appraiser Area of Improvement
    // let tempAppraiserAreaofImprovement = employeeData?.employee?.appraisal_previous_submission?.area_of_improvement;
    // const groupBySpecificAppraiser = _.groupBy(tempAppraiserAreaofImprovement, "value");
    // const groupHandlerAppraiser = groupSpecificAreaHandler(Object.entries(groupBySpecificAppraiser));
    // if (groupHandlerAppraiser?.showArea) {
    //   setShowAppraiserAreaOfImprovement(true);
    //   setAppraiserAreaOfImprovement(groupHandlerAppraiser?.area)
    // } else {
    //   setShowAppraiserAreaOfImprovement(false);
    // }
    setFurtherRecommendationOthers(employeeData?.employee?.appraisal?.other_recommendation_others && employeeData?.employee?.appraisal?.other_recommendation_others);
    setAppraiserFurtherRecommendationOthersCheckbox(employeeData?.employee?.appraisal?.others_checkbox);
    setAppraiserMessageForEmployee(employeeData?.employee?.appraisal?.appraiser_overall_feedback);
    if (employeeData?.employee?.appraisal?.appraiser_overall_feedback !== "" && employeeData?.employee?.appraisal?.appraiser_overall_feedback !== undefined) {
      setShowAppraiserMessageForEmployee(true)
    } else {
      setShowAppraiserMessageForEmployee(false)
    }

    setNormalizerComments(employeeData?.employee?.normalizer_comments);
    setShowNormalizerComments(employeeData?.employee?.normalizer_comments !== "" &&
      employeeData?.employee?.normalizer_comments !== undefined);
    setNormalizerMeetingNotes(employeeData?.employee?.normalizer_meeting_notes);
    setShownormalizermeetingnotes(employeeData?.employee?.normalizer_meeting_notes !== "" &&
      employeeData?.employee?.normalizer_meeting_notes !== null &&
      employeeData?.employee?.normalizer_meeting_notes !== undefined);
    setNormalizerMeetingAttachment(employeeData?.employee?.meetingNotesAttachments);
    setShowNormalizerMeetingAttachment(employeeData?.employee?.meetingNotesAttachments && employeeData?.employee?.meetingNotesAttachments?.length > 0);
    if (employeeData?.employee?.appraisal?.status == "completed" &&
      (employeeData?.employee?.normalizer?.isAppraiserChecked ||
        employeeData?.employee?.normalizer?.isEmployeeChecked ||
        employeeData?.employee?.normalizer?.isReviewerChecked)) {
      setShownormalizerdiscussedrating(true)
    } else {
      setShownormalizerdiscussedrating(false)
    }
    const date = new Date(employeeData?.employee?.employee?.one_to_one_meeting?.slice(0, 10));
  const One_To_One_Meeting_Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    setOneToOneMeetingDate(One_To_One_Meeting_Date && One_To_One_Meeting_Date);
    setShowoneToOneMeetingDate(employeeData?.employee?.employee?.one_to_one_meeting !== "" &&
      employeeData?.employee?.employee?.one_to_one_meeting !== null &&
      employeeData?.employee?.employee?.one_to_one_meeting !== undefined);
    const Overall_rating = employeeData?.employee?.appraisal?.pa_rating;
    const RatingScale = ratingData?.data?.map((j: any) => ({
      rating: j?.rating,
      definition: j?.definition,
      rating_titile: j?.rating_scale,
    }));
    const filterRatingScale = (item: any, minRating: any, maxRating: any) => {
      return (item?.rating >= minRating && item?.rating <= maxRating) && (Overall_rating >= minRating && Overall_rating <= maxRating);
    }
    const FilteredRatingScale = RatingScale?.filter((item: any) => {
      if (filterRatingScale(item, 1, 1.99) ||
        filterRatingScale(item, 2, 2.49) ||
        filterRatingScale(item, 2.5, 2.99) ||
        filterRatingScale(item, 3, 3.49) ||
        filterRatingScale(item, 3.5, 3.99) ||
        filterRatingScale(item, 4, 4.49) ||
        filterRatingScale(item, 4.5, 4.99) ||
        filterRatingScale(item, 5.0, 5.0)) {
        return {
          ratingScale: item?.rating_titile,
          definition: item?.definition,
        };
      }
    });
    if (FilteredRatingScale && FilteredRatingScale.length > 0) {
      setRatingDefinition(FilteredRatingScale[0]?.definition);
      setRatingScaleDefinition(FilteredRatingScale[0]?.rating_titile);
    } else {
      // Handle the case when FilteredRatingScale is empty
      // setratingdefenition("No rating definition found");
    }
  }, [ratingData, employeeData])

  console.log(furtherRecommendation, furtherRecommendationOthers,"furtherRecommendationOthers")
  console.log(employeeData,performanceGoalsFieldValuesEmployee,showPerformanceGoalsFieldValuesEmployee,'checkpg')
  return (
    <>
      <PreviousAppraisalViewPa
        employee_id={employee_id}
        employeeDetails={employeeDetails}
        overallRating={overallRating}
        PAStatus={PAStatus}
        showPotential={showPotential}
        potential={potential}
        ratingScaleDefinition={ratingScaleDefinition}
        ratingDefinition={ratingDefinition}
        employeeData={employeeData}
        ratingData={ratingData}
        objectiveTitleData={objectiveTitleData}
        appraiserFeedbackQuestionnaire={appraiserFeedbackQuestionnaire}
        showAppraiserAreaOfImprovement={showAppraiserAreaOfImprovement}
        appraiserAreaOfImprovement={appraiserAreaOfImprovement}
        employeeAreaOfImprovement={employeeAreaOfImprovement}
        showAppraiserTrainingRecommendation={showAppraiserTrainingRecommendation}
        appraiserTrainingRecommendation={appraiserTrainingRecommendation}
        employeeTrainingRecommendation={employeeTrainingRecommendation}
        appraisalTemplateTrainingRecommendation={appraisalTemplateTrainingRecommendation}
        employeeComments={employeeComments}
        showEmployeeComments = {showEmployeeComments}
        setEmployeeComments={setEmployeeComments}
        oneToOneMeetingDate={oneToOneMeetingDate}
        showoneToOneMeetingDate = {showoneToOneMeetingDate}
        setOneToOneMeetingDate={setOneToOneMeetingDate}
        appraiserMessageForEmployee={appraiserMessageForEmployee}
        showAppraiserMessageForEmployee={showAppraiserMessageForEmployee}
        setAppraiserFeedbackQuestionnaire={setAppraiserFeedbackQuestionnaire}
        normalizerComments={normalizerComments}
        showNormalizerComments={showNormalizerComments}
        reviewerRejectionReason={reviewerRejectionReason}
        showReviewerRejectionReason={showReviewerRejectionReason}
        performanceGoalsFieldValues={performanceGoalsFieldValues}
        performanceGoalsFieldValuesEmployee={performanceGoalsFieldValuesEmployee}
        showEmployeeAreaOfImprovement={showEmployeeAreaOfImprovement}
        showPerformanceGoalsFieldValuesEmployee={showPerformanceGoalsFieldValuesEmployee}
        showPerformanceGoalsFieldValues={showPerformanceGoalsFieldValues}
        showEmployeeTrainingRecommendation={showEmployeeTrainingRecommendation}
        appraiserChecked={appraiserChecked}
        reviewerChecked={reviewerChecked}
        employeeChecked={employeeChecked}
        shownormalizerdiscussedrating={shownormalizerdiscussedrating}
        visiblityColumnData={visiblityColumnData}
        objectiveDescription={objectiveDescription}
        Colors={Colors}
        colorarray={colorarray}
        pdfExportComponent={pdfExportComponent}
        handleHide={handleHide}
        positionHide={positionHide}
        employeecode={employeecode}
        furtherRecommendation={furtherRecommendation}
        furtherRecommendationOthers={furtherRecommendationOthers}
        shownormalizermeetingnotes={shownormalizermeetingnotes}
        normalizerMeetingNotes={normalizerMeetingNotes}
        normalizerMeetingAttachment={normalizerMeetingAttachment}
        showNormalizerMeetingAttachment={showNormalizerMeetingAttachment}

      />
    </>
  )
}
export default Previous_Appraisal_ViewPa