import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Snackbar, Stack } from '@mui/material'
import { useGetRatingScaleQuery, useUpdateEmployeeAppraisalMutation } from '../../../../service';
import { useAppraisalContext } from '../../../../context/appraiserOverviewContext';
import { useCheckRoleLogsMutation } from '../../../../service/employee/employee';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AlertDialogSuccess from "../../../UI/DialogSuccess";
import { useAttachmentsAppraiserOverviewMutation } from "../../../../service/employee/appraisal/appraisal";
import { useGetEmailIdsQuery, useSendEmailNotificationMutation } from "../../../../service/email/emailNotification";
import _ from "lodash";
import { APPRAISER_SUBMITS_TO_REVIEWER } from "../../../../constants/AppraisalEmailContents/NotificationForAction/Appraiser";
import AlertDialogOkCancelForUnsavedChanges from "../../../UI/DialogforunsavedChangesAlert";
import AlertYesNo from "../../../UI/DialogYesNo";
import { useAppraiserContext } from "../../../../context/appraisal/AppraiserContext";
import { CHANGES_SAVED_SUCCESSFULLY, LOADER_MESSAGE, PENDING_WITH_APPRAISER } from "../../../../constants/DialogContents/DialogContents";
import DialogCircularProgressLoader from "../../../UI/DialogCircularProgressLoader";


/************styles for snackbar****************/
const useStyles = makeStyles((theme) => ({
  customAlert: {
    backgroundColor: '#3e8cb5',
    color: "white",
    height: '60px !important',
    alignItems: "center",
    fontSize: "1rem"
  },
  customSnackbar: {
    paddingBottom: '16px',
    paddingRight: '16px',
  },
}));

const AppraiserOverviewFooterButtons = (props: any) => {
  const { disableButtons, setDisableButtons, employee_id, employeeData, attachedFiles, ratingData, tabValue, setTabValue, moveTab, setMoveTab, navPrompt, setNavPrompt, objectiveDescription, employeeDataIsFetching, appraiserRatingPreviousSubmission, fetchCancel, fetchCancel2, disableTextAfterSubmission } = props;
  // @ts-ignore
  const { appraiserFurtherRecommendationOthersCheckbox, appraiserFurtherRecommendationOthers, appraiserFurtherRecommendation, appraiserAreaOfImprovement, potential, appraiserTrainingRecommendation, checkboxIdHandler, checkboxHandler, appraiserFeedbackQuestionnaire, appraiserMessageForEmployee, appraiserPerformanceGoals, } =
    useAppraiserContext()
  let navigate = useNavigate();
  const classes = useStyles();

  /*****************************mutations***********************/
  const { data: emailData } = useGetEmailIdsQuery({ appraiser_code: employeeData?.data?.appraiser_code, reviewer_code: employeeData?.data?.reviewer_code, normalizer_code: employeeData?.data?.normalizer_code })


  const [updateEmployeeAppraisal] = useUpdateEmployeeAppraisalMutation();
  const [updateLoggedRole] = useCheckRoleLogsMutation();
  const [attachmentsAppraiser] = useAttachmentsAppraiserOverviewMutation();

  /***********************states***************************/
  const [successAlertTriger, setSuccessAlertTriger] = useState(false); /***********Alert to show snackbar after saving************/
  const [successAlertTrigerMSG, setSuccessAlertTrigerMSG] = useState(CHANGES_SAVED_SUCCESSFULLY); /***********Alert message in snackbar after saving************/
  const [message, setMessage] = useState("");
  const [openAlertOk, setOpenAlertOk] = useState(false); /******** pop up for validations with Ok button************/
  const [openAlertCancel, setOpenAlertCancel] = useState(false); /******** pop up for validations with Ok and Cancel button************/
  const [sendEmailNotification] = useSendEmailNotificationMutation();
  const [tempTabValue, setTempTabValue] = useState(tabValue); /************* set a temporary tab value ************************/
  const [openAlertForPeformance, setopenAlertForPeformance] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    /********* This will be executed whenever the 'tabValue' prop changes *************/
    setTempTabValue(tabValue);
  }, [tabValue]);

  const saveAsDraftRatings = () => {
    /********* To save the attachments *******************/
    let files = attachedFiles?.map((item: any) => {
      return {
        url: item.name,
        objective_description: item.objective_description,
      };
    });
    attachmentsAppraiser({
      id: employee_id,
      attachments: files,
    });


    /*********** Trim the appraiser comments to remove additional space in the comments ****************/
    let trimAppraiserComments = objectiveDescription?.map((item: any) => {
      return {
        ...item,
        comments: item?.comments?.trim(),
        definition: item?.definition
      }
    });

    /************ Mutation to save as draft all the rating details ***************/
    updateEmployeeAppraisal({
      "appraisal.objective_description": trimAppraiserComments,
      "appraisal.potential": potential,
      "appraisal.appraiser_status": "draft",
      "appraisal.pa_status": "Pending with Appraiser",
      "appraisal.status": "not-started",
      "current_rating.objective_description": trimAppraiserComments,
      id: employee_id,
    }).then((j: any) => {
      if (!j.error) {
        setMoveTab(false);
        setNavPrompt(false);
        setSuccessAlertTrigerMSG("Changes were successfully saved.")
        setSuccessAlertTriger(true);
        setDisableButtons(false);
      } else {
        /***************** Mutation to check the activity logs of each Roles ******************/
        updateLoggedRole({
          pa_action_by: `Appraiser error in saved as draft from Rating tab : ${j.error} : ${new Date()}`,
          id: employee_id
        })
        setMessage("Something Went Wrong.")
        setOpenAlertOk(true)
      }
    });
  }

  const saveAsDraftOverallFeedback = () => {
    /****** check if specific area is not empty and specific action is empty ****************/
    let specificAreaNotEmpty = appraiserAreaOfImprovement?.filter((item: any) => {
      return item.value?.trim() !== ""
    }).filter((item: any) => {
      return item.specific_actions[0]?.value?.trim() === ""
    });
    /****** check if specific action is not empty and specific area is empty ****************/
    let specificActionNotEmpty = appraiserAreaOfImprovement?.filter((item: any) => {
      return item.specific_actions[0]?.value?.trim() !== ""
    }).filter((item: any) => {
      return item?.value?.trim() === ""
    });

    /** if Training category is not empty and training name or training jusification is empty */
    let trainingRecommendationCategoryNotEmpty = appraiserTrainingRecommendation?.filter((item: any) => item.name != "")
      .filter((item: any) => item.justification?.trim() === "" || item.training_name?.trim() === "");
    /** if Training justification is not empty and training category or training name is empty */
    let trainingRecommendationJustificationNotEmpty = appraiserTrainingRecommendation?.filter((item: any) => item.justification != "")
      .filter((item: any) => item.name == "" || item.training_name?.trim() === "")
    /** if Training name is not empty and training category or training jusification is empty */
    let trainingRecommendationNameNotEmpty = appraiserTrainingRecommendation?.filter((item: any) => item.training_name != "")
      .filter((item: any) => item.name == "" || item.justification?.trim() === "")

    /** check if any of the Other recommendation field is empty **********/
    const otherrrecomendation = appraiserFurtherRecommendation?.filter(
      (j: any) => j.isChecked === true
    );

    if (specificAreaNotEmpty?.length > 0 || specificActionNotEmpty?.length > 0) {
      setOpenAlertOk(true);
      setMessage("Please add the missing details in the Areas for Improvement.");
      setTempTabValue(1);
    } else if (trainingRecommendationCategoryNotEmpty?.length > 0 || trainingRecommendationJustificationNotEmpty?.length > 0 || trainingRecommendationNameNotEmpty?.length > 0) {
      setOpenAlertOk(true);
      setMessage("Please add the missing details in the Training Recommendations.")
      setTempTabValue(1);
    } else if (appraiserFurtherRecommendationOthersCheckbox == true && appraiserFurtherRecommendationOthers?.trim() == "" && otherrrecomendation?.length == 0 || appraiserFurtherRecommendationOthersCheckbox == true && appraiserFurtherRecommendationOthers?.trim() == undefined && otherrrecomendation?.length == 0) {
      setOpenAlertOk(true);
      setMessage("It is mandatory to provide details when selecting \"Other\" option in Further Recommendations.")
      setTempTabValue(1);
    } else {
      let trimOverallFeedbackQuestionnaire = appraiserFeedbackQuestionnaire?.map((item: any) => {
        return {
          ...item,
          value: item?.value?.trim()
        }
      });
      let areaValues = appraiserAreaOfImprovement?.filter((item: any) => item.value?.trim() != "").map((item: any) => {
        let trimSpecificAction = item.specific_actions?.map((item: any) => {
          return {
            ...item,
            value: item?.value?.trim()
          }
        })
        return {
          ...item,
          value: item?.value?.trim(),
          specific_actions: trimSpecificAction
        }
      });
      let trainingRecommendationValues = appraiserTrainingRecommendation?.filter((item: any) => item.name != "")?.map((item: any) => {
        return {
          ...item,
          training_name: item?.training_name?.trim(),
          justification: item?.justification?.trim(),
        }
      });
      let trimOtherFurtherRecommendation = appraiserFurtherRecommendationOthers?.trim();
      let filteredOtherRecommendations = appraiserFurtherRecommendation?.filter((j: any) => j?.isChecked === true);

      if (appraiserFurtherRecommendationOthersCheckbox == false || filteredOtherRecommendations?.length > 0) {
        trimOtherFurtherRecommendation = "";
      }

      updateEmployeeAppraisal({
        "appraisal.other_recommendation": checkboxIdHandler(
          checkboxHandler(appraiserFurtherRecommendation)
        ),
        // "appraisal.others_checkbox": otherRecommendationcheckbox,
        "appraisal.other_recommendation_others": trimOtherFurtherRecommendation,
        // @ts-ignore
        "appraisal.others_checkbox": appraiserFurtherRecommendationOthersCheckbox,
        "appraisal.training_recommendation":
          trainingRecommendationValues,
        "appraisal.potential": potential,
        "appraisal.feedback_questions": trimOverallFeedbackQuestionnaire,
        "appraisal.area_of_improvement": areaValues,
        "appraisal.appraiser_overall_feedback": appraiserMessageForEmployee?.trim(),
        "appraisal.appraiser_status": 'draft',
        "appraisal.pa_status": "Pending with Appraiser",
        "appraisal.status": "not-started",
        id: employee_id,
      }).then((res: any) => {
        if (!res.error) {
          setSuccessAlertTrigerMSG("Changes were successfully saved.")
          setSuccessAlertTriger(true)
          setMoveTab(false);
          setNavPrompt(false);
          setDisableButtons(false);
        } else {
          updateLoggedRole({
            pa_action_by: `Appraiser error in saved as draft from Overall Feedback tab : ${res.error} : ${new Date()}`,
            id: employee_id
          });
          setOpenAlertOk(true);
          setMessage("Something Went Wrong.")
        }
      });
    }
  }

  const saveAsDraftPerformanceGoals = () => {
    /** if PerformanceGoal category is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsCategoryNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.goal_category != "")
      .filter((item: any) =>
        item.description?.trim() === "" || item.keyresult?.trim() === "" ||
        item.due_date == "");
    /** if PerformanceGoal description is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsDescriptionNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.description?.trim() != "")
      .filter((item: any) => item.goal_category === "" || item.keyresult?.trim() === "" ||
        item.due_date == "")
    /** if PerformanceGoal keyresult is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalskeyResultNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.keyresult?.trim() != "")
      .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
        item.due_date == "")
    /** if PerformanceGoal dueDate is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsDueDateNotEmpty = appraiserPerformanceGoals?.filter((item: any) => (item.due_date != "" && item.due_date != undefined))
      .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
        item.keyresult?.trim() == "")
    /** if PerformanceGoal remarks is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsRemarksNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.remarks?.trim() != "")
      .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
        item.due_date == "" || item.remarks?.trim() == "")

    if (performanceGoalsCategoryNotEmpty?.length > 0 || performanceGoalsDescriptionNotEmpty?.length > 0 ||
      performanceGoalskeyResultNotEmpty?.length > 0 || performanceGoalsDueDateNotEmpty?.length > 0 ||
      performanceGoalsRemarksNotEmpty?.length > 0) {
      setOpenAlertOk(true);
      setMessage("Please add the missing details in the Performance Goals.")
      setTempTabValue(2);
    } else {
      let performancegoalValues = appraiserPerformanceGoals?.filter((item: any) => item.goal_category !== "")?.map((item: any) => {
        return {
          ...item,
          goal_category: item.goal_category,
          description: item?.description?.trim(),
          keyresult: item?.keyresult?.trim(),
          due_date: item?.due_date,
          remarks: item?.remarks?.trim()
        }
      });
      updateEmployeeAppraisal({
        "appraisal.performance_goal": performancegoalValues,
        "appraisal.potential": potential,
        "appraisal.appraiser_status": "draft",
        "appraisal.pa_status": "Pending with Appraiser",
        "appraisal.status": "not-started",
        id: employee_id,
      }).then((j: any) => {
        if (!j.error) {
          setMoveTab(false);
          setNavPrompt(false);
          setSuccessAlertTrigerMSG("Changes were successfully saved.")
          setSuccessAlertTriger(true);
          setDisableButtons(false);
        } else {
          /***************** Mutation to check the activity logs of each Roles ******************/
          updateLoggedRole({
            pa_action_by: `Appraiser error in saved as draft from PerformanceGoals tab : ${j.error} : ${new Date()}`,
            id: employee_id
          })
          setMessage("Something Went Wrong.")
          setOpenAlertOk(true)
        }
      });
    }
  }

  const handleSaveAsDraft = () => {
    setDisableButtons(true);
    if (tabValue == 0) { saveAsDraftRatings() }
    else if (tabValue == 1) { saveAsDraftOverallFeedback() }
    else if (tabValue == 2) { saveAsDraftPerformanceGoals() }
  }

  const handleCloseSnackbar = () => {
    setSuccessAlertTriger(false)
    setSuccessAlertTrigerMSG("")
  }

  const handleCloseAlertOk = () => {
    setOpenAlertOk(false);
    setMessage("");
    setTabValue(tempTabValue);
    setDisableButtons(false);
  }

  const submitData = () => {
    setShowLoader(true);
    setMessage(LOADER_MESSAGE);  
    let files = attachedFiles?.map((item: any) => {
      return {
        url: item.name,
        objective_description: item.objective_description,
      };
    });
    attachmentsAppraiser({
      id: employee_id,
      attachments: files,
    });
    let overallRating: any = "0";

    if (objectiveDescription?.length > 0 && employeeData) {

      const currentObjectiveDescriptionMapped = objectiveDescription?.map((i: any) => {
        const sum = (i.value * i.objective_type.value) / 10000
        let rating = ratingData?.data?.find((item: any) => item._id == i.ratings)?.rating;
        const newSum = sum * rating
        return newSum ? newSum : 0
      })
      overallRating = _.sum(currentObjectiveDescriptionMapped).toFixed(2)

      let trimAppraiserComments = objectiveDescription?.map((item: any) => {
        return {
          ...item,
          comments: item?.comments?.trim()
        }
      });

      let trimOverallFeedbackQuestionnaire = appraiserFeedbackQuestionnaire?.map((item: any) => {
        return {
          ...item,
          value: item?.value?.trim()
        }
      });

      let areaValues = appraiserAreaOfImprovement?.filter((item: any) => item?.value?.trim() != "")?.map((item: any) => {
        let trimSpecificAction = item.specific_actions?.map((item: any) => {
          return {
            ...item,
            value: item?.value?.trim()
          }
        })
        return {
          ...item,
          value: item?.value?.trim(),
          specific_actions: trimSpecificAction
        }
      });

      let trainingRecommendationValues = appraiserTrainingRecommendation?.filter((item: any) => item.name != "")?.map((item: any) => {
        return {
          ...item,
          training_name: item?.training_name?.trim(),
          justification: item?.justification?.trim(),
        }
      });

      let trimOtherFurtherRecommendation = appraiserFurtherRecommendationOthers?.trim();
      let filteredOtherRecommendations = appraiserFurtherRecommendation?.filter((j: any) => j?.isChecked === true);

      if (filteredOtherRecommendations?.length > 0) {
        trimOtherFurtherRecommendation = "";
      }

      let performancegoalValues = appraiserPerformanceGoals?.filter((item: any) => item.goal_category !== "")?.map((item: any) => {
        return {
          ...item,
          goal_category: item.goal_category,
          description: item?.description?.trim(),
          keyresult: item?.keyresult?.trim(),
          due_date: item?.due_date,
          remarks: item?.remarks?.trim()
        }
      });

      updateEmployeeAppraisal({
        "appraisal.objective_description": trimAppraiserComments,
        "appraisal.appraiser_status": "submitted",
        "appraisal.status": "in-progress",
        "appraisal.appraiser_rejected": false,
        "appraisal.show_appraiser": true,
        "appraisal.pa_status": "Pending with Reviewer",
        "appraisal.pa_rating": overallRating,
        "appraisal.appraiser_rating": overallRating,
        "appraisal.potential": potential,
        "appraisal.feedback_questions": trimOverallFeedbackQuestionnaire,
        "appraisal.area_of_improvement": areaValues,
        "appraisal.training_recommendation":
          trainingRecommendationValues,
        "appraisal.other_recommendation_others": trimOtherFurtherRecommendation,
        // @ts-ignore
        "appraisal.others_checkbox": appraiserFurtherRecommendationOthersCheckbox,
        "appraisal.other_recommendation": checkboxIdHandler(
          checkboxHandler(appraiserFurtherRecommendation)
        ),
        "appraisal.appraiser_overall_feedback": appraiserMessageForEmployee.trim(),
        "appraisal.performance_goal": performancegoalValues,
        "appraisal_previous_submission.objective_description": trimAppraiserComments,
        "appraisal_previous_submission.appraiser_status": "submitted",
        "appraisal_previous_submission.status": "in-progress",
        "appraisal_previous_submission.appraiser_rating": appraiserRatingPreviousSubmission,
        "appraisal_previous_submission.pa_status": "Pending with Reviewer",
        "appraisal_previous_submission.potential": potential,
        "current_rating.objective_description": trimAppraiserComments,
        "current_previous_submission.objective_description": trimAppraiserComments,
        "appraisal_previous_submission.feedback_questions": trimOverallFeedbackQuestionnaire,
        "appraisal_previous_submission.area_of_improvement": areaValues,
        "appraisal_previous_submission.training_recommendation":
          trainingRecommendationValues,
        "appraisal_previous_submission.other_recommendation": checkboxIdHandler(
          checkboxHandler(appraiserFurtherRecommendation)
        ),
        "appraisal_previous_submission.others_checkbox": appraiserFurtherRecommendationOthersCheckbox,
        "appraisal_previous_submission.other_recommendation_others": trimOtherFurtherRecommendation,
        "appraisal_previous_submission.appraiser_overall_feedback": appraiserMessageForEmployee.trim(),
        "appraisal_previous_submission.performance_goal": performancegoalValues,
        "reviewer.status": "in-progress",
        "normalizer.status": "in-progress",
        reviewerIsDisabled: false,
        reviewerIsChecked: false,
        "reviewer.reviewer_status": "pending",
        "appraisal_previous_rating.objective_description": trimAppraiserComments,
        employee: {},
        id: employee_id,
      }).then((j: any) => {
        if (!j.error) {
          setMoveTab(false);
          setDisableButtons(false);
          setSuccessAlertTrigerMSG("The performance appraisal was submitted to the Reviewer.")
          setSuccessAlertTriger(true);
          setShowLoader(false);
          setNavPrompt(false);
          setMessage("")
          updateLoggedRole({
            pa_action_by: `Appraiser (Submitted) : ${new Date()}`,
            id: employee_id
          })

          let appraiserName = employeeData?.data?.appraiser_name;
          let employeeName = employeeData?.data?.first_name;
          let employeeCode = employeeData?.data?.employee_code;
          let calendarName = employeeData?.data?.calendar?.calendar_type;
          let calendarYear = employeeData?.data?.calendar?.year;
          let reviewerFirstName = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.reviewer_code)?.firstName;

          let tempSubject = APPRAISER_SUBMITS_TO_REVIEWER?.subject;
          tempSubject = tempSubject?.replace("[year]", `${calendarYear}`);
          tempSubject = tempSubject?.replace("[calendar name]", `${calendarName}`);
          tempSubject = tempSubject?.replace("[employee name]", `${employeeName}`);
          tempSubject = tempSubject?.replace("[employee code]", `${employeeCode}`);

          let tempHtml = APPRAISER_SUBMITS_TO_REVIEWER?.html;
          tempHtml = tempHtml?.replace("[Reviewer FirstName]", `${reviewerFirstName}`);
          tempHtml = tempHtml?.replace("[year]", `${calendarYear}`);
          tempHtml = tempHtml?.replace("[calendar name]", `${calendarName}`);
          tempHtml = tempHtml?.replace("[employee name]", `${employeeName}`);
          tempHtml = tempHtml?.replace("[Appraiser name]", `${appraiserName}`);
          tempHtml = tempHtml?.replace("[employee code]", `${employeeCode}`);

          let reviewerEmail = emailData?.employeeData?.find((item: any) => item.employee_code === employeeData?.data?.reviewer_code)?.email;
          let email = APPRAISER_SUBMITS_TO_REVIEWER?.to;
          email = email?.replace("[email]", `${reviewerEmail}`);

          sendEmailNotification(
            {
              to: email,
              subject: tempSubject,
              html: tempHtml
            }
          )
          // .then((res: any) => {
          //   if (!res.error) {
          //     setShowLoader(false);
          //     setNavPrompt(false);
          //     setMessage("")
          //   } else {
          //     setOpenAlertOk(true);
          //     setMessage("Something Went Wrong.")
          //   }
          // })
        } else {
          console.log(j, 'checkerrroer')

          updateLoggedRole({
            pa_action_by: `Appraiser (Error - Submitted) (From Rating Tab): ${j.error} : ${new Date()}`,
            id: employee_id
          })
          setOpenAlertOk(true);
          setMessage("Something Went Wrong.")
        }
      })
    }
  }

  const handleSaveAndSubmit = () => {
    setDisableButtons(true);
    let specificArea = appraiserAreaOfImprovement?.filter((item: any) => {
      return item.value?.trim() !== ""
    }).filter((item: any) => {
      return item.specific_actions[0].value?.trim() === ""
    });
    let specificArea1 = appraiserAreaOfImprovement?.filter((item: any) => {
      return item.specific_actions[0].value?.trim() !== ""
    }).filter((item: any) => {
      return item?.value?.trim() === ""
    });
    let otherrrecomendation = appraiserFurtherRecommendation?.filter(
      (j: any) => j.isChecked === true
    );
    /** if Training category is empty and if training name or training jusification is not empty */
    let trainingRecommendationCategoryNotEmpty = appraiserTrainingRecommendation.filter((item: any) => item.name != "")
      .filter((item: any) => item.justification?.trim() === "" || item.training_name?.trim() === "");
    /** if Training justification is not empty and training category or training name is empty */
    let trainingRecommendationJustificationNotEmpty = appraiserTrainingRecommendation.filter((item: any) => item.justification != "")
      .filter((item: any) => item.name == "" || item.training_name?.trim() === "")
    /** if Training name is not empty and training category or training jusification is empty */
    let trainingRecommendationNameNotEmpty = appraiserTrainingRecommendation.filter((item: any) => item.training_name != "")
      .filter((item: any) => item.name == "" || item.justification?.trim() === "")

    // *********** Remarks is not mandatory***************************/
    /** if PerformanceGoal category is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsCategoryNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.goal_category != "")
      .filter((item: any) =>
        item.description?.trim() === "" || item.keyresult?.trim() === "" ||
        item.due_date == "");
    /** if PerformanceGoal description is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsDescriptionNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.description?.trim() != "")
      .filter((item: any) => item.goal_category === "" || item.keyresult?.trim() === "" ||
        item.due_date == "")
    /** if PerformanceGoal keyresult is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalskeyResultNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.keyresult?.trim() != "")
      .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
        item.due_date == "")
    /** if PerformanceGoal dueDate is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsDueDateNotEmpty = appraiserPerformanceGoals?.filter((item: any) => (item.due_date != "" && item.due_date != undefined))
      .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
        item.keyresult?.trim() == "")
    /** if PerformanceGoal remarks is empty and one of the appraiserPerformanceGoals field is not empty */
    let performanceGoalsRemarksNotEmpty = appraiserPerformanceGoals?.filter((item: any) => item.remarks?.trim() != "")
      .filter((item: any) => item.goal_category === "" || item.description?.trim() === "" ||
        item.due_date == "")

    if (objectiveDescription) {
      let Message = "";
      let Status = false;

      objectiveDescription?.forEach((i: any, index: any) => {
        console.log(potential, 'checkpotentialValue')
        if (
          employeeData?.data?.appraisal_template?.potential === true
          &&
          (potential == undefined || potential == null || potential == "")
        ) {
          Status = true;
          Message = "Please select the Potential Level.";
          setTempTabValue(0);
        } else if (i.ratings === "" || i.ratings === undefined) {
          Status = true;
          Message = "It is mandatory to add ratings in all fields.";
          setTempTabValue(0);
        } else if (i.ratings !== "" && i.ratings !== undefined && ratingData) {
          let rating = ratingData?.data?.find((item: any) => i.ratings == item._id).rating;
          if (((rating < 3) || (rating >= 4)) && (i.comments?.trim() == "" || i.comments?.trim() == undefined)) {
            Status = true;
            Message = `It is mandatory to provide a justification for the rating below 3, and 4 and above.`
            setTempTabValue(0);
          }
        }
        if (index == objectiveDescription?.length - 1) {
          if (Status === true) {
            setOpenAlertOk(true);
            setMessage(Message);
            handleSaveAsDraft();
          } else {
            if (
              appraiserFeedbackQuestionnaire?.filter((item: any) => item.value?.trim() == "")?.length > 0 ||
              appraiserFeedbackQuestionnaire?.length == 0
            ) {
              Status = true;
              Message = `Please provide your feedback on the overall feedback questions.`
              setTempTabValue(1);
            }
            else if (specificArea?.length > 0 || specificArea1?.length > 0) {
              Status = true;
              Message = `Please add the missing details in the Areas for Improvement.`
              setTempTabValue(1);
            }
            else if (trainingRecommendationCategoryNotEmpty?.length > 0 || trainingRecommendationNameNotEmpty?.length > 0 || trainingRecommendationJustificationNotEmpty?.length > 0) {
              Status = true;
              Message = `Please add the missing details in the Training Recommendations.`
              setTempTabValue(1);
            }
            else if (appraiserFurtherRecommendationOthersCheckbox == true && appraiserFurtherRecommendationOthers?.trim() == "" && otherrrecomendation?.length == 0 || appraiserFurtherRecommendationOthersCheckbox == true && appraiserFurtherRecommendationOthers?.trim() == undefined && otherrrecomendation?.length == 0) {
              Status = true;
              Message = `It is mandatory to provide details when selecting \"Other\" option in Further Recommendations.`
              setTempTabValue(1);
            }
            else if (performanceGoalsCategoryNotEmpty?.length > 0 || performanceGoalsDescriptionNotEmpty?.length > 0 ||
              performanceGoalskeyResultNotEmpty?.length > 0 || performanceGoalsDueDateNotEmpty?.length > 0 ||
              performanceGoalsRemarksNotEmpty?.length > 0) {
              Status = true;
              Message = `Please add the missing details in the Performance Goals.`
              setTempTabValue(2);
            }

            if (Status === true) {
              setOpenAlertOk(true);
              setMessage(Message);
              handleSaveAsDraft();
            } else {
              // setOpenAlertCancel(true);
              // setMessage("")
              // handleSaveAsDraft();
              //Start :: **********Alert to be shown only if Performance goals are empty ***************/
              if (appraiserPerformanceGoals?.length == 0 || (appraiserPerformanceGoals?.filter((performanceGoalsItem: any) =>
                performanceGoalsItem?.description !== "")?.length == 0)) {
                setopenAlertForPeformance(true)
                setMessage("Would you like to add performance goals for the next period?")
              }
              else {
                submitData()
              }
              //End :: **********Alert to be shown only if Performance goals are empty ***************/
            }
          }
        }
      });
    }
  }

  const handleBack = () => {
    if (moveTab === true) {
      setOpenAlertOk(true);
      setMessage("Please save the changes before leaving the page.");
    } else {
      if (tabValue == 2) {
        setTabValue(1);
      } else if (tabValue == 1) {
        setTabValue(0);
      }
    }
  }


  const cancelRatingChanges = () => {
    setMoveTab(false);
    setNavPrompt(false);
    setOpenAlertCancel(false);
    setMessage("");
    fetchCancel().then((res: any) => {
      navigate(`/dashboardreview`, { state: { from: `${1}` } })
    });
  }

  const cancelOverallFeedbackChanges = () => {
    setMoveTab(false);
    setNavPrompt(false);
    setOpenAlertCancel(false);
    fetchCancel().then((res: any) => setTabValue(0))
  }

  const cancelPerformanceGoalsChanges = () => {
    setMoveTab(false);
    setNavPrompt(false);
    setOpenAlertCancel(false);
    fetchCancel().then((res: any) => setTabValue(1))
  }

  const handleToRejectCancel = () => {
    setOpenAlertCancel(false);
    setMessage("")
  }
  const handleopenAlertForPeformance = () => {
    setopenAlertForPeformance(false)
    setMessage("")
    // if (moveTab === true) {
    //   setOpenAlertOk(true);
    //   setMessage("Please save the changes before leaving the page.");

    // }else{
    setMoveTab(false);
    setTabValue(2)
    setOpenAlertOk(false);
    setMessage("");
    // }
    setDisableButtons(false);
    handleSaveAsDraft()
  }
  // openAlertForPeformance

  const handleToAcceptCancel = () => {
    if (tabValue == 0) { cancelRatingChanges() }
    if (tabValue == 1) { cancelOverallFeedbackChanges() }
    if (tabValue == 2) { cancelPerformanceGoalsChanges() }
  }
  const handleopenAccpetForPeformance = () => {

    setopenAlertForPeformance(false)
    setMessage("")
    submitData()
  }

  const handleCancel = () => {
    if (navPrompt == true || moveTab == true) {
      setOpenAlertCancel(true);
    } else {
      setOpenAlertCancel(false);
      setMessage("");
      if (tabValue == 2) {
        setTabValue(1)
      } else if (tabValue == 1) {
        setTabValue(0)
      } else {
        navigate(`/dashboardreview`, { state: { from: `${1}` } })
      }
    }
  }


  return (
    <>
      {employeeData?.data?.appraisal?.pa_status?.includes(PENDING_WITH_APPRAISER) &&
        <Stack justifyContent="center" spacing={2} direction="row" paddingTop="10px" paddingBottom="10px" >
          <Button
            //   disabled={disableButtons || employeeDataIsFetching || openSnackbar}
            variant="outlined"
            style={{
              borderRadius: "4px",
              textTransform: "none",
              fontSize: "15px",
              fontFamily: "Arial",
              borderColor: "#3E8CB5",
              color: "#3E8CB5",
              height: "35px",
              background: "transparent",
              // ...((disableButtons || employeeDataIsFetching || openSnackbar) && {
              //   cursor: "not-allowed",
              //   borderColor: "#ccc",
              //   color: "#ccc",
              // }),
            }}
            onClick={() => {
              handleSaveAsDraft()
            }}
          >
            Save as Draft
          </Button>

          <Button
            //   disabled={disableButtons || employeeDataIsFetching || openSnackbar}
            variant="outlined"
            style={{
              borderRadius: "4px",
              textTransform: "none",
              fontSize: "15px",
              fontFamily: "Arial",
              borderColor: "#3E8CB5",
              color: "#3E8CB5",
              height: "35px",
              background: "transparent",
              // ...((disableButtons || employeeDataIsFetching || openSnackbar) && {
              //   cursor: "not-allowed",
              //   borderColor: "#ccc",
              //   color: "#ccc",
              // }),
            }}
            onClick={() => {
              handleSaveAndSubmit()
            }}
          >
            Save and Submit
          </Button>

          {tabValue !== 0 && (
            <Button
              // disabled={disableButtons || employeeDataIsFetching || openSnackbar}
              variant="outlined"
              style={{
                borderRadius: "4px",
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Arial",
                borderColor: "#3E8CB5",
                color: "#3E8CB5",
                height: "35px",
                background: "transparent",
                //   ...((disableButtons || employeeDataIsFetching || openSnackbar) && {
                //     cursor: "not-allowed",
                //     borderColor: "#ccc",
                //     color: "#ccc",
                //   }),
              }}
              onClick={() => {
                handleBack()
              }}
            >
              Back
            </Button>
          )}

          <Button
            //   disabled={disableButtons || employeeDataIsFetching || openSnackbar}
            variant="outlined"
            style={{
              borderRadius: "4px",
              textTransform: "none",
              fontSize: "15px",
              fontFamily: "Arial",
              borderColor: "#3E8CB5",
              color: "#3E8CB5",
              height: "35px",
              background: "transparent",
              // ...((disableButtons || employeeDataIsFetching || openSnackbar) && {
              //   cursor: "not-allowed",
              //   borderColor: "#ccc",
              //   color: "#ccc",
              // }),
            }}
            onClick={() => {
              handleCancel()
            }}
          >
            Cancel
          </Button>
        </Stack>
      }

      <AlertDialogSuccess
        isAlertOpen={openAlertOk}
        handleAlertClose={handleCloseAlertOk}
      >
        {message}
      </AlertDialogSuccess>

      <AlertDialogOkCancelForUnsavedChanges
        isAlertOpen={openAlertCancel}
        handleAlertClose={handleToRejectCancel}
        handleAlertIdClose={handleToAcceptCancel}
      >
      </AlertDialogOkCancelForUnsavedChanges>
      <AlertYesNo
        isAlertOpen={openAlertForPeformance}
        handleAlertYes={handleopenAlertForPeformance}
        handleAlertClose={handleopenAccpetForPeformance}
      >
        {message}
      </AlertYesNo>

      <DialogCircularProgressLoader
        isAlertOpen={showLoader}>
        {message}
      </DialogCircularProgressLoader>

      <Snackbar
        className={classes.customSnackbar}
        open={successAlertTriger}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          className={classes.customAlert}
          onClose={handleCloseSnackbar}
          sx={{ width: '100%' }}
          icon={false}
        >
          <b>{successAlertTrigerMSG}</b>
        </Alert>
      </Snackbar>
    </>
  )
}

export default AppraiserOverviewFooterButtons